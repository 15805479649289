
import wait from 'waait'
import qs from 'qs'
import isNull from 'lodash/isNull'
import cloneDeep from 'lodash/cloneDeep'
import { differenceInDays } from 'date-fns'
import transformParams from '@/helpers/transformParams'
import apiDebugger from '@/helpers/apiDebugger'
import getMediaAlphaCarsParams from '@/helpers/getMediaAlphaCarsParams'
import analytics from '@/helpers/analytics'
import { cars as pairings } from '@/static/data/pairings.json'
import insertSojernTag from '@/helpers/insertSojernTag'
import GoogleAdSense from '@/components/GoogleAdSense.vue';

export default {
  data () {
    return {
      apiData: null,
      uuid: null,
      nextPollUrl: null,
      nextPollSleep: 1000,
      pollCount: 0,
      pollLimit: 180,
      cooldown: null,
      isLoading: true,
      inlineAds: null,
      mediaAlphaParams: null,
      compareItems: [],
      compareModalCar: null,
      compareToSearchId: null,
      compareAllUrl: null,
      showMediaAlphaMobileFooterAd: false,
      hasShareTripsChat: false,
      debugData: null,
      modals: {
        compare: true
      }
    }
  },
  computed: {
    rtl () {
      return this.$store.state.rtl
    },
    adSlots () {
      return this.$config.ADSENSE_ID
    },
    adsenseId() {
      return this.$config.ADSENSE_ID ?? null;
    },
    country () {
      return this.$store.state.country
    },
    isMobile () {
      return this.$store.state.device.isMobile
    }
  },
  watch: {
    '$route.query': {
      handler: 'load',
      deep: true
    }
  },
  mounted () {
    this.load()
    try {
      window.adara('send', {
        pageType: 'Results Page',
        siteLanguage: this.$store.state.language,
        siteCountry: this.$store.state.country
      })
    } catch (e) {
      console.info('Error firing Adara event')
    }
  },
  methods: {
    async compareAll(car) {
      const compareAllWindow = window.open('/loading', '_blank')

      // TODO: review this mobile logic and car.partners[0]
      if (this.isMobile && car !== undefined && car !== null && car.partners.length) {
        compareAllWindow.location.href = car.partners[0].partnerDeeplinkUrl
        return
      }

      if (this.compareAllUrl !== null) {
        compareAllWindow.location.href = this.compareAllUrl
        return
      }

      const allProviders = this.compareItems.reduce((providers, item) => {
        providers.push(item.providerCode)
        return providers
      }, [])
      const { entryId } = this.$store.state

      const compareAllQueryString = qs.stringify({
        entryId,
        providerCodes: allProviders.join(','),
        searchId: this.compareToSearchId
      }, { arrayFormat: 'indices' })

      // Results
      try {
        const { url } = await this.$axios.$get(this.$config.API_URL + `/ads/kayak/compare/cars/iframe?${compareAllQueryString}`)
        compareAllWindow.location.href = url
      } catch (e) {
        console.info('Error getting Kayak iframe')
        return this.$toast.error('An error has ocurred, please reload and try again', { position: 'top-center', duration: 4000 })
      }

      this.modals.compare = false
    },
    openCompareModal ({ car }) {
      if (!this.compareItems) {
        return
      }

      this.compareModalCar = (car !== undefined) ? car : null

      if (this.$store.state.inlineAdDeeplink) {
        analytics.track('modal-car-compare-open-click', this)
      } else {
        analytics.track('modal-car-compare-open-auto', this)
      }
      this.modals.compare = true
    },
    closeCompareModal () {
      this.modals.compare = false
    },
    async load () {
      const { query } = this.$route
      const { country, site, entryId, siteType, isIdentified } = this.$store.state
      const { deviceCategory } = this.$store.getters

      this.isLoading = true

      setTimeout(() => {
        this.scrollTo('car-results')
      }, 500)

      if (!isIdentified) {
        console.info('Wait for visitor identification...')
        return setTimeout(() => {
          this.load()
        }, 500)
      }

      const now = new Date()

      if (isNull(this.cooldown) || (now > this.cooldown)) {
        this.cooldown = new Date()
        this.cooldown.setSeconds(this.cooldown.getSeconds() + 5)
      } else {
        console.info('Waiting for cooldown...')
        return setTimeout(() => {
          this.load()
        }, 1000)
      }

      this.uuid = crypto.randomUUID()

      this.$store.commit('clearSearchCarsFilters')
      this.$store.commit('resetSearchResults')
      
      if (!query.pickupCode || !query.dropoffCode || !query.pickupDate || !query.dropoffDate) {
        return this.$router.push('/')
      }

      const apiData = transformParams.toApi(query, pairings)
      this.apiData = apiData

      // Sojern
      try {
        const sojernParams = {
          p: 'search',
          eml: this.$store.state.user.data.email,
          ccid: this.$store.state.entryId,
          tad: null,
          tch: null,
          t: null,
          ppot: 'leisure',
          l: window.navigator.language,
          vid: 'car',
          fow: null,
          hc: null,
          cd: differenceInDays(new Date(apiData.inboundDate), new Date(apiData.outboundDate)),
          pname: window.location.href,
          rc1: apiData.originText.match(/-\s*([\w\s]+),/)?.[1]?.trim() ?? null,
          rs2: apiData.originText.match(/,\s*([A-Z]{2})\s*,/)?.[1]?.trim() ?? null,
          rc2: apiData.destinationText.match(/-\s*([\w\s]+),/)?.[1]?.trim() ?? null,
          rs1: apiData.destinationText.match(/,\s*([A-Z]{2})\s*,/)?.[1]?.trim() ?? null,
          rd1: apiData.outboundDate,
          rd2: apiData.inboundDate,
          ra1: apiData.origin,
          rn1: null,
          rn2: null
        }

        insertSojernTag(sojernParams, this)
      } catch (e) {
        console.info('Error when inserting the Sojern script')
      }

      const params = {
        ...apiData,
        debug: this.$store.state.isDebugEnabled ? 1 : 0,
        device: deviceCategory,
        country: country || 'WW',
        site: site.replace('v2.', '').replace('new.', '').replace('ab.', '').replace('dev-ab.', ''),
        entryId,
        adid: query.gclid || query.msclkid,
        sitebrand: siteType
      }

      // Results
      try {
        const {
          deeplinks,
          results,
          search,
          filters,
          debug
        } = await this.$axios.$get(this.$config.API_URL + '/car-rentals/search', { params })
        if (results) {
          this.$store.commit('appendSearchResults', Object.values(results))
        }

        if (search && search.totalCount == 0 && search.finished == true) {
          const debuggerPayload = {
            params: params,
            search: search,
          }
          apiDebugger.log('no-car-results', entryId, debuggerPayload, this)

          analytics.track('meta-no-car-results-response', this)
        }

        if (debug !== undefined && debug.debugUrl !== undefined) {
          this.debugData = debug
        }

        if (params?.origin && params?.destination) {
          this.$store.commit('setSearchCarLocationCodes', { pickupCode: params.origin, dropoffCode: params.destination })
        }

        if (deeplinks) {
          this.$store.commit('setDeeplinks', Object.values(deeplinks))
        }

        if (filters) {
          this.$store.commit('setSearchCarsCheckboxes', filters)
        }

        if (search && !search.finished) {
          this.nextPollUrl = search.nextPollUrl
          this.nextPollSleep = search.nextPollSleep
          this.pollCount = 0
          this.poll()
        }

        if (search.finished) this.isLoading = false
      } catch (e) {
        console.info('Error getting car results')
        return this.$toast.error('An error has ocurred, please reload and try again', { position: 'top-center', duration: 4000 })
      }

      // Kayak
      try {
        this.compareItems = []
        this.compareToSearchId = null

        params.sitebrand = 'cars'
        params.site= 'multiseater.com'
        const { inlineItems } = await this.$axios.$get(this.$config.API_URL + '/ads/inline/cars', { params })

        this.$store.commit('setInlineAds', inlineItems)

        if (inlineItems !== undefined) {
        /*
          // Disabled for CORS error
          inlineItems.forEach(item => {
            try {
              this.$axios.get(item.impressionUrl, null, {
                "Origin": "farescraper.com",
                "User-Agent": ostype
              });
            } catch (ex) {
              console.info('Error triggering impressionUrl')
            }
          })
        */
        }

        // Get compare partners
        const { compareToItems, searchId, compareAllUrl } = await this.$axios.$get(this.$config.API_URL + '/ads/compare/cars', { params })
        this.compareItems.push(...compareToItems)
        this.compareToSearchId = searchId
        this.compareAllUrl = compareAllUrl
        this.$store.commit('setCompareToAdsModal', true)
        this.$store.commit('setCompareItems', this.compareItems)
      } catch (e) {
        console.info('Error inserting Kayak ads')
      }

      // Media Alpha
      try {
        const placements = { 'SxaeIlTyfbn8I7vgAYj3s4YV7ByT3w': 'MEDIA_ALPHA_A' }

        this.mediaAlphaParams = getMediaAlphaCarsParams(entryId, params, Object.keys(placements))
        window.MediaAlphaExchange = cloneDeep(this.mediaAlphaParams)

        setTimeout(() => {
          window.MediaAlphaExchange__load(placements)
        }, 200)
      } catch (e) {
        console.info('Error inserting Media Alpha Ads')
      }

      // console.log(results)
    },
    async poll () {
      if (this.pollCount >= this.pollLimit) {
        console.info('Poll limit reached')
        return
      }

      this.pollCount = this.pollCount + 1
      await wait(this.nextPollSleep)

      try {
        const { results, search, filters } = await this.$axios.$get(this.nextPollUrl)

        if (results) {
          this.$store.commit('resetSearchResults')
          this.$store.commit('appendSearchResults', Object.values(results))
        }

        if (filters && filters.airlines) {
          this.$store.commit('setSearchAirlines', Object.values(filters.airlines))
        }

        if (search && !search.finished) {
          this.nextPollUrl = search.nextPollUrl
          this.nextPollSleep = search.nextPollSleep
          this.poll()
        }

        if (search.finished) this.isLoading = false
      } catch (e) {
        console.info('Error polling')
      }
    },
    scrollTo (id) {
      const el = document.getElementById(id)
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    },
  }
}
