
import fireRTBHouseEvents from '../helpers/fireRTBHouseEvents'
import analytics from '@/helpers/analytics'

export default {
  computed: {
    siteType () {
      return this.$store.state.siteType
    },
    phone () {
      return this.$store.getters.phone
    },
    logo () {
      return `/images/logos/${this.$store.state.site}.png`
    }
  },
  methods: {
    clickPhone (event) {
      try {
        fireRTBHouseEvents('flight', 'call', {
          origin: null,
          destination: null,
          id: null
        })
      } catch (error) {
        console.info('Error firing RTB event')
      }
      analytics.track('phone-click', this)
      window.top.location = `tel:${this.phone}`
    }
  }
}
