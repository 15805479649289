

import FlightsListItem from '@/components/FlightsListItem.vue'

export default {
    components: {
      FlightsListItem
    },
    props: {
        item: {
            type: Object,
            default: null
        }
    }
}
