
import vClickOutside from 'v-click-outside'
import createPopUnder from '@/helpers/createPopUnder'
import analytics from '@/helpers/analytics'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      submitted: false,
      form: {
        email: null,
        name: null,
        source: 'newsletter',
        dunhill: false
      }
    }
  },
  computed: {
    site () {
      return this.$store.state.site
    },
    siteType () {
      return this.$store.state.siteType
    },
    icon () {
      return `/images/icons/${this.site}.png`
    },
    showDunhillCheck () {
      return false

      /*
      const { country } = this.$store.state
      return country.toUpperCase() === 'US'
      */
    }
  },
  mounted () {
    // Call the function to load Google Sign-In
    this.initializeGoogleSignIn()
  },
  methods: {
    // Initialize Google Sign-In
    initializeGoogleSignIn () {
      google.accounts.id.initialize({
        client_id: '852348261721-sfmlak3mqho4g8ffo9l5lf045u8tnprm.apps.googleusercontent.com',
        callback: this.handleCredentialResponse
      })

      google.accounts.id.renderButton(document.getElementById('btnGoogleSignIn'),
        {
          type: 'standard',
          size: 'large',
          text: 'signup_with',
          logo_alignment: 'left',
          click_listener: this.onClickHandler,
          heigth: 60,
          width: 336
        })
    },

    onClickHandler () {
      console.log('LC Sign up with Google button clicked...')
    },

    // Handle the credential response
    handleCredentialResponse (response) {
      const credential = response.credential
      const decodedCredential = this.parseJwt(credential)

      this.form.email = decodedCredential.email
      this.form.name = decodedCredential.name
      this.form.source = 'googleBtn'
      this.submit()
    },

    // Parse JWT token
    parseJwt (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      return JSON.parse(jsonPayload)
    },

    blurNewsletterInput () {
      analytics.track('newsletter-email-fill', this)
    },
    close () {
      this.$store.commit('setNewsletterModal', false)

      this.triggerCloseEvent()

      if (
        window.popUnder ||
        this.$store.state.device.isMobile ||
        this.$store.state.device.isTablet ||
        this.$store.state.browser.name === 'safari') { return }

      createPopUnder()
    },
    triggerCloseEvent () {
      // Trigger event if form was not submitted
      if (!this.submitted) {
        analytics.track('newsletter-closed-empty', this)
      }
    },
    async submit () {
      const entryId = this.$store.state.entryId
      this.submitted = true
      try {
        const payload = {
          emailaddress: this.form.email,
          name: this.form.name,
          source: this.form.source,
          dunhill: this.form.dunhill
        }

        analytics.track('newsletter-submit', this, payload)

        await this.$axios.$post(`newsletter/subscribe/${entryId}`, payload)
        this.$store.commit('user/set', this.form)
        this.close()
      } catch (e) {
        return this.$toast.error('An error has ocurred, please reload and try again', { position: 'top-center', duration: 4000 })
      }

      this.$store.commit('user/set', this.form)
      this.close()

      const sha256 = require('simple-sha256')
      const emailSha256 = await sha256(this.form.email)

      try {
        window.adara('identity', {
          email: emailSha256
        })
      } catch (e) {
        console.error(e)
        console.info('Error firing Adara event')
      }
    }
  }
}
