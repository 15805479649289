
import { mixin as clickaway } from 'vue-clickaway2'
import { first } from 'lodash'
import analytics from '@/helpers/analytics'

export default {
  mixins: [clickaway],
  data () {
    const DEFAULT_CURRENCY = 'USD'
    return {
      isOpen: false,
      currency: DEFAULT_CURRENCY,
      DEFAULT_CURRENCY
    }
  },
  computed: {
    hasLocalCurrency () {
      return this.$store.state.hasLocalCurrency
    },
    hasResults () {
      return this.$store.state.searchResults.length > 0
    },
    firstResult () {
      return first(this.$store.state.searchResults)
    },
    currencies () {
      return {
        local: this.firstResult.currencyLocal,
        default: this.DEFAULT_CURRENCY
      }
    }
  },
  watch: {
    hasLocalCurrency: {
      handler () {
        if (this.currency === this.DEFAULT_CURRENCY && this.hasResults) {
          this.currency = this.firstResult.currencyLocal
        }
      },
      immediate: true
    }
  },
  methods: {
    clickCurrencySelector () {
      analytics.track('currency-bar-click', this)
    },
    toggle () {
      this.isOpen = !this.isOpen
    },
    close () {
      this.isOpen = false
    },
    changeCurrency (keyCurrencies) {
      this.currency = this.currencies[keyCurrencies]
      this.$store.commit('setLocalCurrency', this.currency !== this.DEFAULT_CURRENCY)
      this.close()
    }
  }
}
