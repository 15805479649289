export default ({ app, store, $config }) => {
  // just for flight vertical domains
  if (store.state.siteBrand !== 'flightgorilla') return

  // Client-side only
  if (process.client) {
    const pixelId = $config.TABOOLA_PIXEL_ID

    if (!pixelId) {
      console.warn('Taboola Pixel ID is missing')
      return
    }

    // Initialize main tagging code
    const script = document.createElement('script')
    script.innerHTML = `
      window._tfa = window._tfa || [];
      window._tfa.push({notify: 'event', name: 'page_view', id: ${pixelId}});
      !function (t, f, a, x) {
        if (!document.getElementById(x)) {
          t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
        }
      }(document.createElement('script'),
      document.getElementsByTagName('script')[0],
      '//cdn.taboola.com/libtrc/unip/${pixelId}/tfa.js',
      'tb_tfa_script');
    `
    document.head.appendChild(script)
  }
}
