// plugins/rtbHouse.js
export default ({ app, store, $config }) => {
  // just for flight vertical domains
  if (store.state.siteType !== 'flights') return

  // Client-side only
  if (process.client) {
    const pixelId = $config.RTB_HOUSE_PIXEL_ID

    if (!pixelId) {
      console.warn('RTB House Pixel ID is missing')
      return
    }

    // Initialize main tagging code
    const script = document.createElement('script')
    script.innerHTML = `
      (function(w,d,dn,t){
        w[dn] = w[dn] || [];
        w[dn].push({eventType:'init', value:t, dc:'us'});
        var f = d.getElementsByTagName('script')[0],
            c = d.createElement('script');
        c.async = true;
        c.src = 'https://tags.creativecdn.com/wrLMKht5Ndo3RZUGjTrg.js';
        f.parentNode.insertBefore(c,f);
      })(window,document,'rtbhEvents','${pixelId}');
    `
    document.head.appendChild(script)
  }
}
