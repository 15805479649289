
import vClickOutside from 'v-click-outside'
import analytics from '@/helpers/analytics'
import fireConversionEvents from '@/helpers/fireConversionEvents'
import fireRTBHouseEvents from '@/helpers/fireRTBHouseEvents'

const providers = ['ladybug', 'emirates', 'priceline', 'smartfares']

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    flight: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {
      providers,
      showAllProviders: false,
      showCompareProviders: 3,
      maxCompareProviders: 7
    }
  },
  computed: {
    googleConversion () {
      return this.$store.state.siteConfig.googleConversions ? this.$store.state.siteConfig.googleConversions.compareAds : this.$store.state.siteConfig.googleConversion
    },
    isMobile () {
      return this.$store.state.deviceType !== 'desktop'
    },
    hasFlight () {
      return this.flight !== null
    },
    flightPartners () {
      return this.flight ? this.flight.partners : []
    },
    parsedItems () {
      const limit = this.isMobile ? this.maxCompareProviders - 2 : this.maxCompareProviders

      return [...this.flightPartners, ...this.items].slice(0, limit).map((item, index) => ({
        ...item,
        visible: this.showAllProviders || (index < this.showCompareProviders),
        order: index < 3 ? 1 : 3
      }))
    },
    showClickToCall () {
      return false
    },
    toggleShowMoreText () {
      return this.showAllProviders ? 'search.lessProviders' : 'search.moreProviders'
    },
    shouldShowShowMore () {
      return this.items.length > this.showCompareProviders
    },
    site () {
      return this.$store.state.site
    },
    siteType () {
      return this.$store.state.siteType
    },
    localCurrency () {
      return this.$store.state.localCurrency
    },
    displaySymbol () {
      return this.localCurrency ? this.flight.currencySymbolLocal : this.flight.currencySymbol || this.flight.currency
    },
    displayPrice () {
      return this.localCurrency ? this.flight.minPriceLocal : this.flight.minPrice
    }
  },
  methods: {
    toggleShowAll () {
      this.showAllProviders = !this.showAllProviders
    },
    compareAll () {
      const eventName = (this.flight === null) ? 'compare-all-click' : 'compare-all-click-flight'
      analytics.track(eventName, this)
      this.$emit('compareAll', this.flight)
    },
    compare (index) {
      let item = this.items[index]
      ++index

      if ((item.partner !== undefined) && (item.partner == 'travelmedia')) {
        fireConversionEvents(this, {
          google: {
            conversion: this.googleConversion,
          },
          bing: {
            eventName: "CompareAd Clickout",
          },
          value: Number(item.bid),
          currency: 'USD',
        })

        // RTBHouse
        this.fireRTBHouseEvent(item)
      }

      analytics.track(`compare-${index}-click`, this)
    },
    partnerClick() {
      // Conversion
      if (this.flight !== null && this.flight.cpc !== undefined) {
        fireConversionEvents({
          google: {
            conversion: this.googleConversion,
          },
          bing: {
            eventName: "CompareAd Clickout",
          },
          value: Number(this.flight.cpc).toFixed(2),
          currency: 'USD',
        })

        // RTBHouse
        this.fireRTBHouseEvent(this.flight)
      }
    },
    fireRTBHouseEvent (item) {
      const cpc = item.bid ?? item.cpc
      try {
        // RTBHouse
        fireRTBHouseEvents('flight', 'conversion', {
          id: item.id + '-' + Date.now(),
          origin: '',
          destination: '',
          cpc,
          outboundDate: '',
          inboundDate: '',
          adults: '',
          children: '',
          roundtrip: ''
        })
      } catch (error) {
        console.info('Error firing RTB event')
      }
    },
    closeWithDeepLink () {
      analytics.track('compare-no-thanks-click', this)
      if (this.$store.state.inlineAdDeeplink) {
        window.newTab = window.open('/loading', '_blank')
        window.newTab.location.href = this.$store.state.inlineAdDeeplink
        this.$store.commit('setInlineAdDeeplink', null)
      }
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    }
  }
}
