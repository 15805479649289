
import fireRTBHouseEvents from '../helpers/fireRTBHouseEvents'

const links = [
  { type: 'flights', label: 'Flights', url: 'https://flightsearchdirect.com/?utm_source=' },
  { type: 'cars', label: 'Cars', url: 'https://carsearchdirect.com/?utm_source=' },
  // { type: 'cruises', label: 'Cruises', url: 'https://cruisesearchdirect.com/?utm_source=' },
  { type: 'hotels', label: 'Hotels', url: 'https://hotelsearchdirect.com/?utm_source=' }
]

export default {
  data () {
    return {
      links
    }
  },
  computed: {
    showHeaderLinks () {
      return this.$store.state.siteConfig.showHeaderLinks
    },
    siteType () {
      return this.$store.state.siteType
    },
    linksUtmSource () {
      return this.$store.state.siteConfig.headerLinksUtmSource
    }
  },
  methods: {
    hanldeRedirection(type) {
      try {
        fireRTBHouseEvents('flight', 'custom', {
          vertical: type,
          timestamp: Date.now()
        })
      } catch (error) {
        console.info('Error firing RTB event')
      }
    }
  }
}
