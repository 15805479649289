export default function (obj, payload) {

    if (payload.value == 0) {
        return
    }

    // Google conversion
    let googleConversion = (payload.google && payload.google.conversion) ? payload.google.conversion : obj.$store.state.siteConfig.googleConversion
    if (obj.$gtag && googleConversion) {
        obj.$gtag.event('conversion', {
            send_to: googleConversion,
            value: payload.value,
            currency: payload.currency
        })
    }

    // Bing conversion
    if (window.uetq) {
        window.uetq.push('event', (payload.bing && payload.bing.eventName) ? payload.bing.eventName : 'purchase', {
            revenue_value: payload.value,
            currency: payload.currency
        })
    }

    // Facebook conversion
    if (window.fbq) {
        window.fbq('track', 'Purchase', {
            value: payload.value,
            currency: payload.currency
        })
    }
}
