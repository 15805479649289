
const links = [
  { id: 'aboutUs', route: '/about' },
  { id: 'contactUs', route: '/contact' },
  { id: 'privacyPolicy', route: '/privacy' },
  { id: 'terms', route: '/terms' },
  { id: 'blog', route: '/blog' }
]

export default {
  data () {
    const domain = this.$store.state.siteBrand
    const tld = this.$store.state.site.replace(new RegExp(`.*${domain}`), '')

    const email = ['mailto:contact@', domain, tld].join('')
    return {
      links: links.map(link => ({
        ...link,
        url: link.mail ? email : null
      }))
    }
  }
}
