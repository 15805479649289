
import each from 'lodash/each'
import isNull from 'lodash/isNull'
import compact from 'lodash/compact'
import take from 'lodash/take'
import uniqBy from 'lodash/uniqBy'
import { mixin as clickaway } from 'vue-clickaway2'
import analytics from '@/helpers/analytics'
import filterLocalPlaces from '@/helpers/filterLocalPlaces'
import localPlaces from '@/static/data/places.json'

const textTemplate = ({ airportcode, cityname }, includeAirportCode) => {
  return compact([
    cityname,
    includeAirportCode ? airportcode : null
  ]).join(' - ')
}

const parsePlaces = (places, includeAirportCode) => places.map(place => ({ ...place, text: place.name, uiText: textTemplate(place, includeAirportCode) }))

export default {
  mixins: [clickaway],
  props: {
    eventName: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    defaults: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      keyword: '',
      localResults: [],
      endpointResults: [],
      cancelTokens: {},
      previousTimestamp: 0,
      cursor: 0,
      value: null,
      id: null,
      isOpen: false,
      isLoading: false,
      hasSearched: false
    }
  },
  computed: {
    siteType () {
      return this.$store.state.siteType
    },
    results () {
      return uniqBy([
        ...this.localResults,
        ...this.endpointResults
      ], 'id')
    }
  },
  async created () {
    if (this.defaults.value) {
      this.value = this.defaults.value
      this.keyword = this.defaults.value
    } else if (this.defaults.keyword) {
      this.keyword = this.defaults.keyword
    }

    if (this.keyword) {
      await this.search()
      this.selectSuggestion(0)
    }
  },
  methods: {
    open () {
      this.isOpen = true

      const el = this.$refs.input
      el.select()

      if (this.$store.state.device.isMobile || this.$store.state.device.isTablet) {
        const top = el.getBoundingClientRect().top + document.documentElement.scrollTop - el.offsetHeight - 10
        window.scrollTo(0, top)
      }
    },
    clickaway () {
      this.selectCurrentSuggestion()
      this.close()
    },
    close () {
      this.isOpen = false
    },
    async search () {
      if (this.keyword.length === 0) {
        this.isLoading = false
        this.localResults = []
        this.endpointResults = []
        return
      }

      if (this.keyword.length < 2) return

      this.isLoading = true

      each(this.cancelTokens, (cancelToken) => {
        cancelToken.cancel()
      })

      const timestamp = Date.now()
      this.cancelTokens[timestamp] = this.$axios.CancelToken.source()

      const isHotels = this.siteType === 'hotels'

      let endpoint = 'airports'
      if (this.siteType === 'hotels') endpoint = 'cities'
      if (this.siteType === 'cars') endpoint = 'places'

      const filteredLocalPlaces = take(filterLocalPlaces(this.keyword, localPlaces), 20)
      this.localResults = parsePlaces(filteredLocalPlaces, !isHotels)

      try {
        const places = await this.$axios.$get(endpoint, {
          params: {
            q: this.keyword,
            locale: this.$store.state.language,
            // short: 1,
            sitebrand: this.siteType
          },
          cancelToken: this.cancelTokens[timestamp].token
        })
        const parsed = parsePlaces(places, !isHotels)
        this.endpointResults = parsed
        this.hasSearched = true
        this.isLoading = false
      } catch (e) {
        if (this.$axios.isCancel(e)) {
          console.info('Request canceled')
        } else {
          this.isLoading = false
        }
      }
    },
    moveUp () {
      if (this.cursor === 0) return

      this.cursor = this.cursor - 1
    },
    moveDown (e) {
      if (isNull(this.cursor)) {
        this.cursor = 0
        return
      }

      if (this.cursor === this.results.length - 1) return

      this.cursor = this.cursor + 1
    },
    selectCurrentSuggestion () {
      this.selectSuggestion(this.cursor)
    },
    selectSuggestion (index) {
      if (!this.results.length) return
      this.cursor = index
      const suggestion = this.results[index]
      this.value = suggestion.airportcode
      this.keyword = suggestion.uiText
      this.id = suggestion.id
      this.$emit('select', { value: this.value, keyword: this.keyword, data: suggestion })

      if (this.eventName.length && (this.id !== '')) {
        analytics.track(this.eventName, this, {
          unique: this.value,
          siteType: this.siteType,
          value: this.value,
          keyword: this.keyword,
          id: this.id
        })
      }

      this.close()
    },
    focus () {
      this.$refs.input.focus()
    }
  }
}
