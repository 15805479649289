export const AdsMultiCity = () => import('../../components/AdsMultiCity.vue' /* webpackChunkName: "components/ads-multi-city" */).then(c => wrapFunctional(c.default || c))
export const AppBody = () => import('../../components/AppBody.vue' /* webpackChunkName: "components/app-body" */).then(c => wrapFunctional(c.default || c))
export const AppCurrencySelector = () => import('../../components/AppCurrencySelector.vue' /* webpackChunkName: "components/app-currency-selector" */).then(c => wrapFunctional(c.default || c))
export const AppDropdown = () => import('../../components/AppDropdown.vue' /* webpackChunkName: "components/app-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderLinks = () => import('../../components/AppHeaderLinks.vue' /* webpackChunkName: "components/app-header-links" */).then(c => wrapFunctional(c.default || c))
export const AppLanguageSelector = () => import('../../components/AppLanguageSelector.vue' /* webpackChunkName: "components/app-language-selector" */).then(c => wrapFunctional(c.default || c))
export const AppLoading = () => import('../../components/AppLoading.vue' /* webpackChunkName: "components/app-loading" */).then(c => wrapFunctional(c.default || c))
export const AppMobileFooter = () => import('../../components/AppMobileFooter.vue' /* webpackChunkName: "components/app-mobile-footer" */).then(c => wrapFunctional(c.default || c))
export const AppModal = () => import('../../components/AppModal.vue' /* webpackChunkName: "components/app-modal" */).then(c => wrapFunctional(c.default || c))
export const AppSearchForm = () => import('../../components/AppSearchForm.vue' /* webpackChunkName: "components/app-search-form" */).then(c => wrapFunctional(c.default || c))
export const BlogAuthorBio = () => import('../../components/BlogAuthorBio.vue' /* webpackChunkName: "components/blog-author-bio" */).then(c => wrapFunctional(c.default || c))
export const BlogAuthorSign = () => import('../../components/BlogAuthorSign.vue' /* webpackChunkName: "components/blog-author-sign" */).then(c => wrapFunctional(c.default || c))
export const BlogBreadcrumbs = () => import('../../components/BlogBreadcrumbs.vue' /* webpackChunkName: "components/blog-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BlogFeaturedPost = () => import('../../components/BlogFeaturedPost.vue' /* webpackChunkName: "components/blog-featured-post" */).then(c => wrapFunctional(c.default || c))
export const BlogFooter = () => import('../../components/BlogFooter.vue' /* webpackChunkName: "components/blog-footer" */).then(c => wrapFunctional(c.default || c))
export const BlogHeaderAuthor = () => import('../../components/BlogHeaderAuthor.vue' /* webpackChunkName: "components/blog-header-author" */).then(c => wrapFunctional(c.default || c))
export const BlogListDefault = () => import('../../components/BlogListDefault.vue' /* webpackChunkName: "components/blog-list-default" */).then(c => wrapFunctional(c.default || c))
export const BlogPagination = () => import('../../components/BlogPagination.vue' /* webpackChunkName: "components/blog-pagination" */).then(c => wrapFunctional(c.default || c))
export const BlogPost = () => import('../../components/BlogPost.vue' /* webpackChunkName: "components/blog-post" */).then(c => wrapFunctional(c.default || c))
export const BlogPostImage = () => import('../../components/BlogPostImage.vue' /* webpackChunkName: "components/blog-post-image" */).then(c => wrapFunctional(c.default || c))
export const BlogPostShare = () => import('../../components/BlogPostShare.vue' /* webpackChunkName: "components/blog-post-share" */).then(c => wrapFunctional(c.default || c))
export const BlogSeriesNumber = () => import('../../components/BlogSeriesNumber.vue' /* webpackChunkName: "components/blog-series-number" */).then(c => wrapFunctional(c.default || c))
export const BlogSubscriptionForm = () => import('../../components/BlogSubscriptionForm.vue' /* webpackChunkName: "components/blog-subscription-form" */).then(c => wrapFunctional(c.default || c))
export const ButtonTelephoneCall = () => import('../../components/ButtonTelephoneCall.vue' /* webpackChunkName: "components/button-telephone-call" */).then(c => wrapFunctional(c.default || c))
export const CarsFilters = () => import('../../components/CarsFilters.vue' /* webpackChunkName: "components/cars-filters" */).then(c => wrapFunctional(c.default || c))
export const CarsHeroSlogan = () => import('../../components/CarsHeroSlogan.vue' /* webpackChunkName: "components/cars-hero-slogan" */).then(c => wrapFunctional(c.default || c))
export const CarsList = () => import('../../components/CarsList.vue' /* webpackChunkName: "components/cars-list" */).then(c => wrapFunctional(c.default || c))
export const CarsListItem = () => import('../../components/CarsListItem.vue' /* webpackChunkName: "components/cars-list-item" */).then(c => wrapFunctional(c.default || c))
export const CarsSearchForm = () => import('../../components/CarsSearchForm.vue' /* webpackChunkName: "components/cars-search-form" */).then(c => wrapFunctional(c.default || c))
export const CompareFlightLeg = () => import('../../components/CompareFlightLeg.vue' /* webpackChunkName: "components/compare-flight-leg" */).then(c => wrapFunctional(c.default || c))
export const DebugBar = () => import('../../components/DebugBar.vue' /* webpackChunkName: "components/debug-bar" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const DropdownCompareItem = () => import('../../components/DropdownCompareItem.vue' /* webpackChunkName: "components/dropdown-compare-item" */).then(c => wrapFunctional(c.default || c))
export const DropdownPartnerItem = () => import('../../components/DropdownPartnerItem.vue' /* webpackChunkName: "components/dropdown-partner-item" */).then(c => wrapFunctional(c.default || c))
export const FlightsFilters = () => import('../../components/FlightsFilters.vue' /* webpackChunkName: "components/flights-filters" */).then(c => wrapFunctional(c.default || c))
export const FlightsFiltersOptions = () => import('../../components/FlightsFiltersOptions.vue' /* webpackChunkName: "components/flights-filters-options" */).then(c => wrapFunctional(c.default || c))
export const FlightsHeroSlogan = () => import('../../components/FlightsHeroSlogan.vue' /* webpackChunkName: "components/flights-hero-slogan" */).then(c => wrapFunctional(c.default || c))
export const FlightsList = () => import('../../components/FlightsList.vue' /* webpackChunkName: "components/flights-list" */).then(c => wrapFunctional(c.default || c))
export const FlightsListItem = () => import('../../components/FlightsListItem.vue' /* webpackChunkName: "components/flights-list-item" */).then(c => wrapFunctional(c.default || c))
export const FlightsListItemLeg = () => import('../../components/FlightsListItemLeg.vue' /* webpackChunkName: "components/flights-list-item-leg" */).then(c => wrapFunctional(c.default || c))
export const FlightsListItemLegDivider = () => import('../../components/FlightsListItemLegDivider.vue' /* webpackChunkName: "components/flights-list-item-leg-divider" */).then(c => wrapFunctional(c.default || c))
export const FlightsLoading = () => import('../../components/FlightsLoading.vue' /* webpackChunkName: "components/flights-loading" */).then(c => wrapFunctional(c.default || c))
export const FlightsSearchForm = () => import('../../components/FlightsSearchForm.vue' /* webpackChunkName: "components/flights-search-form" */).then(c => wrapFunctional(c.default || c))
export const GoogleAdSense = () => import('../../components/GoogleAdSense.vue' /* webpackChunkName: "components/google-ad-sense" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/HomeHero.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const HomeHeroBulletpoints = () => import('../../components/HomeHeroBulletpoints.vue' /* webpackChunkName: "components/home-hero-bulletpoints" */).then(c => wrapFunctional(c.default || c))
export const HomeSteps = () => import('../../components/HomeSteps.vue' /* webpackChunkName: "components/home-steps" */).then(c => wrapFunctional(c.default || c))
export const HotelsHeroSlogan = () => import('../../components/HotelsHeroSlogan.vue' /* webpackChunkName: "components/hotels-hero-slogan" */).then(c => wrapFunctional(c.default || c))
export const HotelsSearchForm = () => import('../../components/HotelsSearchForm.vue' /* webpackChunkName: "components/hotels-search-form" */).then(c => wrapFunctional(c.default || c))
export const IconsCalendar = () => import('../../components/IconsCalendar.vue' /* webpackChunkName: "components/icons-calendar" */).then(c => wrapFunctional(c.default || c))
export const IconsCall = () => import('../../components/IconsCall.vue' /* webpackChunkName: "components/icons-call" */).then(c => wrapFunctional(c.default || c))
export const IconsCar = () => import('../../components/IconsCar.vue' /* webpackChunkName: "components/icons-car" */).then(c => wrapFunctional(c.default || c))
export const IconsCaret = () => import('../../components/IconsCaret.vue' /* webpackChunkName: "components/icons-caret" */).then(c => wrapFunctional(c.default || c))
export const IconsCollapse = () => import('../../components/IconsCollapse.vue' /* webpackChunkName: "components/icons-collapse" */).then(c => wrapFunctional(c.default || c))
export const IconsEnvelope = () => import('../../components/IconsEnvelope.vue' /* webpackChunkName: "components/icons-envelope" */).then(c => wrapFunctional(c.default || c))
export const IconsExpand = () => import('../../components/IconsExpand.vue' /* webpackChunkName: "components/icons-expand" */).then(c => wrapFunctional(c.default || c))
export const IconsFacebook = () => import('../../components/IconsFacebook.vue' /* webpackChunkName: "components/icons-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsFlight = () => import('../../components/IconsFlight.vue' /* webpackChunkName: "components/icons-flight" */).then(c => wrapFunctional(c.default || c))
export const IconsHotel = () => import('../../components/IconsHotel.vue' /* webpackChunkName: "components/icons-hotel" */).then(c => wrapFunctional(c.default || c))
export const IconsPhone = () => import('../../components/IconsPhone.vue' /* webpackChunkName: "components/icons-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsTakeOff = () => import('../../components/IconsTakeOff.vue' /* webpackChunkName: "components/icons-take-off" */).then(c => wrapFunctional(c.default || c))
export const IconsTwitter = () => import('../../components/IconsTwitter.vue' /* webpackChunkName: "components/icons-twitter" */).then(c => wrapFunctional(c.default || c))
export const InputAirport = () => import('../../components/InputAirport.vue' /* webpackChunkName: "components/input-airport" */).then(c => wrapFunctional(c.default || c))
export const InputCabinClass = () => import('../../components/InputCabinClass.vue' /* webpackChunkName: "components/input-cabin-class" */).then(c => wrapFunctional(c.default || c))
export const InputDate = () => import('../../components/InputDate.vue' /* webpackChunkName: "components/input-date" */).then(c => wrapFunctional(c.default || c))
export const InputPassengers = () => import('../../components/InputPassengers.vue' /* webpackChunkName: "components/input-passengers" */).then(c => wrapFunctional(c.default || c))
export const InputPassengersCounter = () => import('../../components/InputPassengersCounter.vue' /* webpackChunkName: "components/input-passengers-counter" */).then(c => wrapFunctional(c.default || c))
export const InputPassengersCounterButton = () => import('../../components/InputPassengersCounterButton.vue' /* webpackChunkName: "components/input-passengers-counter-button" */).then(c => wrapFunctional(c.default || c))
export const KayakAdInline = () => import('../../components/KayakAdInline.vue' /* webpackChunkName: "components/kayak-ad-inline" */).then(c => wrapFunctional(c.default || c))
export const ModalCompare = () => import('../../components/ModalCompare.vue' /* webpackChunkName: "components/modal-compare" */).then(c => wrapFunctional(c.default || c))
export const ModalCompareClickToCall = () => import('../../components/ModalCompareClickToCall.vue' /* webpackChunkName: "components/modal-compare-click-to-call" */).then(c => wrapFunctional(c.default || c))
export const ModalNewsletter = () => import('../../components/ModalNewsletter.vue' /* webpackChunkName: "components/modal-newsletter" */).then(c => wrapFunctional(c.default || c))
export const SponsoredResults = () => import('../../components/SponsoredResults.vue' /* webpackChunkName: "components/sponsored-results" */).then(c => wrapFunctional(c.default || c))
export const StaticSection = () => import('../../components/StaticSection.vue' /* webpackChunkName: "components/static-section" */).then(c => wrapFunctional(c.default || c))
export const StaticSectionAddress = () => import('../../components/StaticSectionAddress.vue' /* webpackChunkName: "components/static-section-address" */).then(c => wrapFunctional(c.default || c))
export const StaticSectionContact = () => import('../../components/StaticSectionContact.vue' /* webpackChunkName: "components/static-section-contact" */).then(c => wrapFunctional(c.default || c))
export const StaticSectionDomain = () => import('../../components/StaticSectionDomain.vue' /* webpackChunkName: "components/static-section-domain" */).then(c => wrapFunctional(c.default || c))
export const StaticSectionEmail = () => import('../../components/StaticSectionEmail.vue' /* webpackChunkName: "components/static-section-email" */).then(c => wrapFunctional(c.default || c))
export const StaticSectionWebsiteName = () => import('../../components/StaticSectionWebsiteName.vue' /* webpackChunkName: "components/static-section-website-name" */).then(c => wrapFunctional(c.default || c))
export const StaticSectionWord = () => import('../../components/StaticSectionWord.vue' /* webpackChunkName: "components/static-section-word" */).then(c => wrapFunctional(c.default || c))
export const VerifiedResults = () => import('../../components/VerifiedResults.vue' /* webpackChunkName: "components/verified-results" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
