export default function (type = 'flight', form = {}, originData = {}, destinationData = {}) {
  if (!window || !window.fbq) return

  let payload

  if (type === 'flight') {
    payload = {
      content_type: type,
      departing_departure_date: form.outboundDate,
      returning_departure_date: form.inboundDate,
      origin_airport: originData.airportcode,
      destination_airport: destinationData.airportcode,
      num_adults: form.adults,
      num_children: form.children,
      num_infants: 0,
      travel_class: form.cabinClass,
      currency: 'USD',
      value: 0
    }
  }

  if (type === 'hotel') {
    payload = {
      checkin_date: form.outboundDate,
      checkout_date: form.inboundDate,
      // num_adults: form.adults,
      // num_children: form.children,
      city: destinationData.cityname,
      country: destinationData.countryname,
      content_type: type,
      content_ids: 'hotel',
      currency: 'USD',
      value: 0
    }
  }

  fbq('track', 'ViewContent', payload)
  // fbq('track', 'Purchase', payload)
  fbq('track', 'InitiateCheckout', payload)
  fbq('track', 'Search', payload)
}
