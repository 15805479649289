import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=c38d26a0"
import script from "./AppHeader.vue?vue&type=script&lang=js"
export * from "./AppHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeaderLinks: require('/home/forge/farescraper.com/components/AppHeaderLinks.vue').default,AppLanguageSelector: require('/home/forge/farescraper.com/components/AppLanguageSelector.vue').default,AppCurrencySelector: require('/home/forge/farescraper.com/components/AppCurrencySelector.vue').default,IconsCall: require('/home/forge/farescraper.com/components/IconsCall.vue').default,ButtonTelephoneCall: require('/home/forge/farescraper.com/components/ButtonTelephoneCall.vue').default})
